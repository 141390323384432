.gatewaylist {
    padding: 0.5em;
    margin-top: 0.5em;
    border: 1px solid gray;
    border-radius: 0.5em;
    display: flex;
    cursor: pointer;
    box-shadow: 0px 3px 8px #00000029;
}

.bz-text-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 90%;
}

.access_bg { background-color: #f1f1f1; border: none;padding: 20px;min-height: 280px;}
.access_bg1 { padding: 2.5em 1.5em  !important; min-height: 281px;border: none;}
.config_btn,a.config_btn { background-color: #cfcfcf; padding: 10px 0;width: 100%; font-size: 1rem; font-weight: 600;color:#212529}
.config_btn:hover,a.config_btn:hover { background-color: #0d6efd; color: #fff;}
.simple_intger { background-color: #f1f1f1;padding: 0.8em; margin-top: 0.5em;
    border-radius: 0.5em;  cursor: pointer;  box-shadow: 0px 3px 8px #00000029;}
.simple_intger h4 { font-size: 16px; font-weight: 800;color: #000;padding-bottom: 10px;}
.simple_intger p { font-size: 14px; font-weight: 400;color: #000;padding-bottom: 10px;}
.simple_intger_radio { text-align: left;}
.simple_intger:hover {background-color:#0c4758;}
.simple_intger:hover h4, .simple_intger:hover p { color: #fff;}
.selectbarcode label {display: block !important; font-weight: 600 !important; font-size: 14px !important;
}
.selectbarcode1 label { display: inline-block !important;padding-top: 0;padding-left: 8px;}
.selectbarcodeone { display: block; padding-bottom: 10px;}
.selectbarcodeone label {display: inline-block !important;padding-top: 0;}
.durationminute { border: 1px solid #ddd; border-radius: 4px;padding: 0 5px;display: block; margin-bottom: 10px;} 

// Self CheckIn Portal Start
.selfcheckin { background-color: #e2e2e2; min-height: 400px;width: 150px;}
.service-label label { font-weight: 700 !important;}
.service-label .k-input {height: 25px;}
.bz-checkins-config-cards{
    max-width: 39rem;
    min-height: 7.5rem;
    margin-bottom: 1.25rem;
    cursor: default;
    .bz-list-profile{
        color: #202022;
        display: flex;
        align-items: center;
        > .bz-list-profile-detail{
            .bz-list-profile-lead{
                line-height: 1.5;
                font-size: 1rem;
            }
            .bz-list-profile-caption{
                font-size: 0.75rem;
                line-height: 1rem;
                color: #666678;
            }
            .bz-panel-arrow-icon {
                padding: 0 0.625rem;
                padding-right: 0;
                font-size: 1.35rem;
                align-self: stretch;
                position: relative;
                width: 3rem;
                cursor: pointer;
                text-align: center;
            }
        }
    }
  }
  .bz-checkins-config-cards.simple_intger_selected{
    border: 1px solid #008db9!important;
  }
  .bz-checkins-config-cards.disable-card{
    cursor: not-allowed;
    background-color: #cbcbcb;
    opacity: 0.5;
  }
.barcodelabel, .selectbarcode1 label.barcodelabel, .service-label .barcodelabel .k-checkbox-label, .checkicon{font-size:12px !important;}
.selectbarcode1 label.barcodelabel, .service-label .barcodelabel .k-checkbox-label{font-weight: normal !important;}
.barcodelabel.fs14, .logactivity{ font-size: 14px !important;}
.border-activitylog{border: 1px solid #e6e6e6 !important}
.checkicon{color:#07c007}
.popupidel{margin: 10px 5px;text-align: center; background: #fcdfa9;padding: 15px;border-radius: 5px; border: 1px solid #f5d9a6;}
.idelpop .k-window-title{font-weight: bold;  font-size: 14px !important;}





