
.progress_bar.progress {
  display: flex;
  justify-content: center;

  &_Strong {
    ul.k-reset {
      li.k-item.k-selected {
        background-color: green !important;
      }
    }
  }

  &_Medium {
    ul.k-reset {
      li.k-item.k-selected {
        background-color: orange !important;
      }
    }
  }

  &_Weak {
    ul.k-reset {
      li.k-item.k-selected {
        background-color: red !important;
      }
    }
  }
}
.bzo-login-header {
  color: #008db9;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  h5 {
    font-size: 1.3rem;
  }
}
.buttons-container {
  .sign-up-btn {
    width: 100%;
    max-width: 180px;
  }
  align-items: center;
  text-transform: initial;

  .sign-up-btn {
    font-size: 1.2rem;
  }
}
.form-group {
  position: relative;

  label {
    text-transform: initial;
    color: #000000 !important;
  }

  .k-input {
    min-height: 40px;
    height: 5.6vh;
  }
  .AffiliateEmail {
    position: relative;

  }

  img {
    position: absolute;
    top: 40px;
    right: 16px;
    transform: translateY(-7px);
    width: 24px;
  }

  .k-form-error {
    text-transform: initial;
  }
}

.password_item {
  display: flex;
  align-items: center;
  position: relative;
}
span.password_eye_icon {
  position: absolute;
  margin-left: -25px;
  z-index: 1;
  top: 0.5rem;
}
