#bz-notfound {
}

#bz-notfound .bz-notfound {
    position: absolute;
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.bz-notfound {
    max-width: 40rem;
    width: 100%;
    padding-left: 80px;
    line-height: 1.1;
}

.bz-notfound .bz-notfound-404 {
    position: absolute;
    left: 18px;
    top: 13px;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-image: url(https://uatchargehub.buzops.com/Assets/Images/emoji.png);
    background-size: cover;
}


.bz-notfound .bz-notfound-404:before {
    content: "";
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    border-radius: 50%;
    z-index: 1;
    background-color: #b77f7f;


}

.bz-notfound h1 {
    font-family: 'Nunito', sans-serif;
    font-size: 4.0625rem;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.625rem;
    text-transform: uppercase;
    color: #151723;
}

.bz-notfound h2 {
    font-family: 'Nunito', sans-serif;
    font-size: 1.3125rem;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    color: #151723;
}

.bz-notfound p {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    color: #856404;
    margin-top: 0.35rem;

}

@media (max-width:767px) {
    #bz-notfound .bz-notfound {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;
        padding: 3rem;
        text-align: center;
        position: unset;
        transform: unset;
        max-width: inherit;
    }

    .bz-notfound .bz-notfound-404 {
        position: unset;
        width: 7rem;
        height: 7rem;
    }

    .bz-notfound .bz-notfound-404:before {

        position: unset;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    #bz-notfound .bz-notfound {
        max-width: 650px;
    }
}

// buzops-window
.k-window-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.k-window-content::-webkit-scrollbar {
    width: 3px;
    background-color: #F5F5F5;
}

.k-window-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #726b6b;
}