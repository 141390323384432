@font-face {
  font-family: "HelveticaNeue Medium";
  font-weight: medium;
  font-style: medium;
  src: url("../../scss/fonts/HelveticaNeue\ Medium.ttf");
}

@font-face {
  font-family: "HelveticaNeue Medium Extended";
  font-weight: medium;
  font-style: medium;
  src: url("../../scss/fonts/Helvetica\ Neue\ Medium\ Extended.ttf");
}

@font-face {
  font-family: "HelveticaNeue Normal";
  font-weight: normal;
  font-style: normal;
  src: url("../../scss/fonts/Helvetica.ttf");
}

/*******************    reportsCommonFilters   **************************/

.reports-container {
  display: flex;
  width: 100%;

  .reports-header {
    h2 {
      margin-left: 2rem;
      color: #393939;
      opacity: 1;
      font-size: 28px;
      font-family: "HelveticaNeue Medium";
    }
  }

  .reports-date-range {
    display: flex;
    align-items: center;
    justify-content: right;

    h2 {
      font-size: 16px;
      color: #003e52;
      margin-right: 1rem;
      font-family: "Helvetica Neue normal";
    }

    .reports-date-range-dropdown {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 5px;
    }

    .reports-date-range-custom {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 5px;
    }
  }

  .reports-side-header {
    display: flex;
    align-items: center;
    justify-content: right;

    h2 {
      font-size: 20px;
      color: #393939;
      margin-right: 1rem;
      font-family: "HelveticaNeue Medium";
    }

    .reports-date-dropdown {
      width: 16rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 7px;
    }
  }

  .reports-horizontal-line {
    padding-left: 2rem;
    padding-right: 2rem;

    hr {
      border-top: 2px solid #d9d9d9;
    }
  }
}

.k-popup {
  border-radius: 0.25rem;
  margin: 0;
  padding: 0.5rem 0.5rem;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@media screen and (max-width: 653px) and (min-width: 280px) {
  .reports-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .reports-header {
      width: 100%;

      h2 {
        margin-left: 0.6rem;
      }
    }

    .reports-date-range {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 0.6rem 0rem 0.6rem 1.3rem;

      h2 {
        white-space: nowrap;
        font-size: 17px;
      }
    }
  }
}

@media screen and (max-width: 667px) and (min-width: 375px) {
  .reports-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .reports-header {
      width: 100%;

      h2 {
        margin-left: 6px;
        color: #393939;
        opacity: 1;
        font-size: 28px;
        font-family: "HelveticaNeue Medium";
      }
    }

    .reports-date-range {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 0.5rem;

      h2 {
        font-size: 16px;
        display: flex;
        color: #003e52;
        font-family: "Helvetica Neue normal";
      }

      .reports-date-range-dropdown {
        display: flex;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
      }
    }

    .reports-side-header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2rem;

      h2 {
        font-size: 20px;
        color: #393939;
        font-weight: bold;
        font-family: "HelveticaNeue Medium";
      }

      .reports-date-dropdown {
        display: flex;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .reports-container {

    //need to implement
    .reports-header {

      //need to implement
      h2 {
        //need to implement
      }
    }
  }
}

@media screen and (max-width: 844px) and (min-width: 390px) {
  .reports-container {
    .reports-header {
      h2 {
        margin-left: 0.8rem;
        font-size: 32px;
      }
    }

    .reports-date-range {
      h2 {
        font-size: 17px;
      }
    }

    .reports-side-header {
      h2 {
        font-size: 22px;
      }
    }
  }
}

@media screen and (max-width: 896px) and (min-width: 414px) {
  .reports-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .reports-header {
      width: 100%;

      h2 {
        margin-left: 1rem;
        color: #393939;
        opacity: 1;
        font-size: 32px;
        font-family: "HelveticaNeue Medium";
      }
    }

    .reports-date-range {
      display: flex;

      h2 {
        font-size: 20px;
        color: #003e52;
        font-family: "Helvetica Neue normal";
      }

      .reports-date-range-dropdown {
        display: flex;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
      }
    }

    .reports-side-header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2rem;

      h2 {
        font-size: 26px;
        color: #393939;
        font-weight: bold;
        font-family: "HelveticaNeue Medium";
      }

      .reports-date-dropdown {
        display: flex;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 914px) and (min-width: 412px) {
  .reports-container {
    .reports-date-range {
      h2 {
        font-size: 20px !important;
      }
    }

    .reports-side-header {
      h2 {
        font-size: 27px !important;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) {
  .reports-container {
    .reports-date-range h2 {
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: 1180px) and (min-width: 820px) {
  .reports-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .reports-header {
      width: 0rem;

      h2 {
        color: #393939;
        opacity: 1;
        font-size: 28px;
        font-family: "HelveticaNeue Medium";
      }
    }

    .reports-date-range {
      display: flex;
      width: 23rem;
      padding-left: 7rem;
      flex-direction: row;
      align-items: center;

      h2 {
        font-size: 22px !important;
        color: #003e52;
        margin-right: 1rem;
        font-family: "Helvetica Neue normal";
      }

      .reports-date-range-dropdown {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
      }
    }

    .reports-side-header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 3rem;

      h2 {
        font-size: 20px !important;
        color: #393939;
        margin-right: 1rem;
        font-weight: bold;
        font-family: "HelveticaNeue Medium";
      }

      .reports-date-dropdown {
        width: 16rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 7px;
      }
    }
  }
}

/************************** ReportesLandingPage ***********************/
.reports-landing-main {
  margin: 1rem;

  .reports-landing-section h2 {
    font-size: 28px;
    color: #393939;
    font-family: "HelveticaNeue Medium";
  }

  .reports-landing-box {
    padding-top: 1rem;

    .reports-landing-nav-item {
      background: #fbfbfb 0% 0% no-repeat padding-box;
      border: 1px solid #e3e3e3;

      .reports-landing-content-view {
        padding: 0.5rem;

        .reports-landing-text-wrap {
          white-space: nowrap !important;
          padding-left: 0.8rem;

          .reports-landing-image {
            vertical-align: middle;
            width: 25px;
            height: 27px;
          }

          .reports-landing-sub-heading {
            padding-left: 1.1rem;
            vertical-align: middle;
            text-decoration: none;
            font-size: 20px;
            color: #000000;
            font-family: "HelveticaNeue Normal";
          }

          .reports-landing-arrow-right {
            float: right;
            font-size: 1.7rem;
            color: #84898c;
          }
        }
      }
    }

    :hover {
      background: #e3e3e3 0% 0% no-repeat padding-box;
    }
  }
}

/*******************     subscribers-Plan    **************************/

.subscribers-main-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;

  .subscribers-close-icon {
    li {
      font-size: 1.2rem;
      color: #008db9;
      margin-top: 0.3rem;
      margin-right: 0.3rem;
    }
  }

  .subscribers-main-header {
    padding: 0.1rem 2rem;

    .subscriber-header {
      font-size: 26px;
      color: #393939;
      padding-bottom: 1rem;
      font-family: "HelveticaNeue Medium";
    }

    .subscribers-labels {
      display: flex;

      .subscribers-card {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 4px #00000029;
        border: 1px solid #9f9f9f;
        border-radius: 10px;
        font-size: 12px;
        color: #ffffff;
        width: 20rem;
        height: 16rem;
        padding: 1rem 1rem;
        cursor: pointer;

        h2 {
          font-size: 52px;
          color: #7b7b7b;

          padding: 0.5rem;
          font-family: "Helvetica Neue normal", sans-serif;
        }

        span {
          font-size: 16px;

          color: #008db9;
          padding: 0.5rem;
          font-family: "HelveticaNeue Medium";
        }

        &.clicked {
          background: transparent linear-gradient(152deg, #7fc6dc 0%, #008db9 100%) 0% 0% no-repeat padding-box;
          cursor: pointer;
        }

        &.clicked h2 {
          color: #ffffff;
        }

        &.clicked span {
          color: #ffffff;
        }

        &.clicked p {
          color: #ffffff;
        }

        p {
          font-size: 12px;

          color: #000000;
          padding: 0.5rem;
          margin-top: 0.5rem;
          opacity: 0.6;
          font-family: "Helvetica Neue normal", sans-serif;
        }
      }

      .subscribers-grid-parent {
        width: 100%;

        .subscribers-card-content {
          display: flex;

          .subscribers-box {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 4px #00000029;
            border: 1px solid #9f9f9f;
            border-radius: 10px;
            padding: 1rem 1rem;
            margin-top: 0.2rem;
            margin-bottom: 1.7rem;
            margin-left: 1.2rem;
            cursor: pointer;

            .subsctibers-box-content {
              display: flex;

              p {
                color: #000000;
                font-size: 12px;
                font-family: "Helvetica Neue normal", sans-serif;
              }

              h2 {
                font-size: 35px;
                color: #7b7b7b;
                font-family: "Helvetica Neue normal", sans-serif;
              }
            }

            span {
              font-size: 15px;
              color: #008db9;
              float: right;
              font-family: "HelveticaNeue Medium";
            }

            &.clicked {
              background: transparent linear-gradient(152deg, #7fc6dc 0%, #008db9 100%) 0% 0% no-repeat padding-box;
              cursor: pointer;
            }

            &.clicked p {
              color: white;
            }

            &.clicked h2 {
              color: white;
            }

            &.clicked span {
              color: white;
            }
          }
        }
      }
    }
  }

  .subscribers-grid-border {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 9px;
    padding: 2rem 2rem;
    margin-bottom: 3rem;
    margin-left: 2rem;
    margin-right: 2rem;

    .subscribers-search-header {
      justify-content: flex-end;

      .subscribers-settings {
        width: 2.8rem;
        height: 2.5rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
        font-size: 21px;
      }

      .subscribers-search {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
        align-items: center;

        input {
          border: none;
        }
      }

      .subscribers-grid-button-header {
        .subscribers-plan-add-client {
          width: 2.8rem;
          height: 2rem;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #707070;
          border-radius: 5px;
        }

        .subscribers-download {
          width: 2.8rem;
          height: 2.5rem;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #707070;
          border-radius: 5px;
        }

        .subscribers-fullscreen {
          width: 2.8rem;
          height: 2.5rem;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #707070;
          border-radius: 5px;
        }
      }
    }
  }

  .k-grid {
    border-color: transparent !important;
  }

  .k-header {
    background-color: #9f9f9f;
    border: none;
    color: #ffffff;
    font-family: "Helvetica Neue normal", sans-serif;
  }

  .k-grid-table {
    width: 100% !important;
  }

  .k-grid-header-locked>table,
  .k-grid-header-wrap>table {
    width: 100% !important;
  }

  .k-grid-header {
    border-color: #dee2e6;
    color: #212529;
    background-color: #9f9f9f;
  }

  .k-grid col.k-sorted,
  .k-grid th.k-sorted {
    // need to implement
  }

  .k-grid-header .k-i-sort-asc-sm,
  .k-grid-header .k-i-sort-desc-sm,
  .k-grid-header .k-sort-order {
    color: #ffffff;
    border: none;
  }

  .k-grid-content::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #d9d9d9;
  }
}

@media screen and (max-width: 667px) and (min-width: 375px) {
  .subscribers-main-container {
    padding: 1rem 1rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #707070;

    .subscriber-header {
      font-size: 18px;
      color: #393939;
      font-family: "HelveticaNeue Medium";
    }

    .subscribers-labels {
      display: flex;
      flex-direction: column;

      .subscribers-card {
        background: transparent linear-gradient(152deg, #7fc6dc 0%, #008db9 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        font-size: 12px;
        color: #ffffff;
        width: 100%;
        height: 16rem;
        padding: 1rem 1rem;

        h2 {
          font-size: 52px;
          color: #ffffff;
          padding: 0.5rem;
          font-family: "Helvetica Neue normal", sans-serif;
        }

        span {
          font-size: 16px;
          color: #ffffff;
          padding: 0.5rem;
          font-family: "HelveticaNeue Medium";
        }

        p {
          font-size: 12px;
          color: #ffffff;
          padding: 0.5rem;
          margin-top: 0.5rem;
          opacity: 0.6;
          font-family: "Helvetica Neue normal", sans-serif;
        }
      }

      .subscribers-grid-parent {
        width: 100%;

        .subscribers-card-content {
          display: flex;
          margin-left: -1rem;
          margin-right: -0.2rem;
          flex-direction: column;

          .subscribers-box {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 4px #00000029;
            border: 1px solid #9f9f9f;
            border-radius: 10px;
            padding: 1rem 1rem;
            margin-top: 0.8rem;

            .subsctibers-box-content {
              display: flex;

              p {
                color: #000000;
                font-size: 14px;
                font-family: "Helvetica Neue normal", sans-serif;
              }

              h2 {
                font-size: 37px;
                color: #7b7b7b;
                font-family: "Helvetica Neue normal", sans-serif;
              }
            }

            span {
              font-size: 16px;
              color: #008db9;
              float: right;
              font-family: "HelveticaNeue Medium";
            }
          }
        }
      }
    }

    .subscribers-grid-border {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 9px;
      padding: 1rem 1rem;

      .subscribers-search-header {
        justify-content: flex-end;
        flex-direction: column;

        .subscribers-search {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #707070;
          border-radius: 5px;
          align-items: center;
          width: 100%;
          margin-bottom: 1rem;

          input {
            border: none;
          }
        }

        .subscribers-grid-button-header {
          .subscribers-plan-add-client {
            width: 2.8rem;
            height: 2rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 5px;
          }

          .subscribers-download {
            width: 2.8rem;
            height: 2.5rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 5px;
          }

          .subscribers-fullscreen {
            width: 2.8rem;
            height: 2.5rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 844px) and (min-width: 390px) {
  .subscribers-main-container {
    .subscriber-header {
      font-size: 19px;
    }

    .subscribers-labels {
      .subscribers-card {
        height: 20rem;

        span {
          font-size: 17px;
        }

        p {
          font-size: 19px;
        }
      }

      .subscribers-grid-parent {
        .subscribers-card-content {
          .subscribers-box {
            margin-top: 1.2rem;
            margin-bottom: 0.9rem;

            .subsctibers-box-content {
              p {
                font-size: 17px;
              }

              h2 {
                font-size: 40px;
              }
            }

            span {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 896px) and (min-width: 414px) {
  .subscribers-main-container {
    .subscriber-header {
      font-size: 21px;
    }

    .subscribers-labels {
      .subscribers-card {
        width: 100%;
        height: 20rem;

        span {
          font-size: 19px;
        }

        p {
          font-size: 19px;
        }
      }

      .subscribers-grid-parent {
        .subscribers-card-content {
          .subscribers-box {
            margin-top: 1.2rem;
            margin-bottom: 0.9rem;

            .subsctibers-box-content {
              p {
                font-size: 17px;
              }

              h2 {
                font-size: 40px;
              }
            }

            span {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) {
  .subscribers-main-container {
    .subscribers-labels {
      .subscribers-card {
        width: 23rem;
        height: 17rem;

        h2 {
          padding: 0.2rem !important;
        }

        span {
          padding: 0.3rem !important;
        }

        p {
          padding: 0.4rem !important;
        }
      }

      .subscribers-grid-parent {
        width: 100%;

        .subscribers-card-content {
          display: flex;

          .subscribers-box {
            padding: 0.7rem 0.9rem;
            margin-top: 0.1rem;
            margin-bottom: 0.7rem;
            margin-left: 1.2rem;

            .subsctibers-box-content {
              display: flex;

              p {
                font-size: 12px !important;
              }

              h2 {
                font-size: 30px;
              }
            }

            span {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1180px) and (min-width: 820px) {
  .subscribers-main-container {
    padding: 1rem 1rem;

    .subscriber-header {
      font-size: 28px !important;
    }
  }
}

@media screen and (max-width: 1300px) and (min-width: 857px) {
  .subscribers-main-container {
    .subscribers-labels {
      .subscribers-card {
        h2 {
          padding: 0.3rem;
        }

        span {
          padding: 0.3rem;
        }

        p {
          font-size: 15px;
          padding: 0.3rem;
        }
      }

      .subscribers-grid-parent {
        .subscribers-card-content {
          .subscribers-box {
            .subsctibers-box-content {
              p {
                font-size: 13.5px;
              }

              h2 {
                margin-right: -0.5rem;
              }
            }
          }
        }
      }
    }
  }
}

.subscribers-fullview {
  overflow: auto;
  width: 100%;
  height: 100% !important;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: white;
}

.subscribers-settings-view {
  position: relative;
}

.popup-content {
  padding: 30px;
  color: #787878;
  background-color: #fcf7f8;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

/*******************     Sales-Plan    **************************/
.sales-main-container,
.taxes-main-container {
  width: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;

  .sales-close-icon {
    li {
      font-size: 1.2rem;
      color: #008db9;
      margin-top: 0.3rem;
      margin-right: 0.3rem;
    }
  }

  .sales-sub-header {
    padding: 0.1rem 2rem;

    .sales-header {
      font-family: "HelveticaNeue Medium";
      color: #393939;
      font-size: 26px;
    }

    .sales-line-header {
      .sales-line-button {
        font-family: "Helvetica Neue normal", sans-serif;
        font-size: 16px;
        color: #003e52;
        border: none;
        cursor: pointer;
        background-color: #ffffff;

        li {
          border: 1px solid #707070;
        }
      }

      &.clicked .sales-line-button {
        color: #008db9;
      }
    }

    .sales-bar-header {
      .sales-bar-button {
        font-family: "Helvetica Neue normal", sans-serif;
        font-size: 16px;
        color: #003e52;
        border: none;
        background-color: #ffffff;
        cursor: pointer;
      }

      &.clicked .sales-bar-button {
        color: #008db9;
      }
    }
  }

  .sales-graphs-header {
    padding: 0.5rem 4rem;

    .slaes-line-graphs {
      width: 100%;
      height: 18rem;
    }

    .slaes-bar-graphs {
      width: 100%;
      height: 18rem;
    }
  }

  .sales-radio-button-headers {
    padding: 0 2rem;

    .sales-compare {
      float: right;
      border: 1px solid #707070;
      border-radius: 5px;
      margin-right: 0.2rem;
    }
  }

  .sales-border-header {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 9px;
    padding: 2rem 2rem;
    margin: 2rem 2rem;

    .sales-fullview {
      overflow: auto;
      width: 100%;
      height: 100% !important;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 100;
      background-color: white;
      padding: 1rem 2rem;
    }

    .sales-header-search {
      display: flex;
      justify-content: flex-end;

      .sales-search-input {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
        align-items: center;

        input {
          border: none;
        }
      }

      .sales-download-header {
        .sales-download-button {
          width: 2.8rem;
          height: 2.5rem;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #707070;
          border-radius: 5px;
        }
      }

      .sales-fullscreen-header {
        .sales-fullscreen-button {
          width: 2.8rem;
          height: 2.5rem;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #707070;
          border-radius: 5px;
        }
      }
    }
  }

  .k-grid {
    border-color: transparent !important;
  }

  .k-header {
    background-color: #9f9f9f;
    color: #ffffff;
    font-family: "Helvetica Neue normal", sans-serif;
  }

  .k-grid-table {
    width: 100% !important;
  }

  .k-grid-header-locked>table,
  .k-grid-header-wrap>table {
    width: 100% !important;
  }

  .k-grid-header {
    border-color: #dee2e6;
    color: #212529;
    background-color: #9f9f9f;
  }

  .k-grid col.k-sorted,
  .k-grid th.k-sorted {
  }

  .k-grid-header .k-i-sort-asc-sm,
  .k-grid-header .k-i-sort-desc-sm,
  .k-grid-header .k-sort-order {
    color: #ffffff;
    border: none;
  }
}

/**************** Sales By All Signups ************/
.sales-signup-main-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;

  .sales-signup-close-icon {
    li {
      font-size: 1.2rem;
      color: #008db9;
      margin-top: 0.3rem;
      margin-right: 0.3rem;
    }
  }

  .sales-signup-sub-header {
    padding: 0.1rem 2rem;

    .sales-signup-header {
      font-family: "HelveticaNeue Medium";
      color: #393939;
      font-size: 26px;
    }
  }

  .sales-signup-grid-border {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 9px;
    padding: 2rem 2rem;
    margin-bottom: 3rem;
    margin-left: 2rem;
    margin-right: 2rem;

    .sales-signup-search-header {
      justify-content: flex-end;

      .sales-signup-settings {
        width: 2.8rem;
        height: 2.5rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
        font-size: 21px;
      }

      .sales-signup-search {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
        align-items: center;

        input {
          border: none;
        }
      }

      .sales-signup-download {
        width: 2.8rem;
        height: 2.5rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
      }

      .sales-signup-fullscreen {
        width: 2.8rem;
        height: 2.5rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
      }
    }
  }

  .k-grid {
    border-color: transparent !important;
  }

  .k-header {
    background-color: #9f9f9f;
    border: none;
    color: #ffffff;
    font-family: "Helvetica Neue normal", sans-serif;
  }

  .k-grid-table {
    width: 100% !important;
  }

  .k-grid-header-locked>table,
  .k-grid-header-wrap>table {
    width: 100% !important;
  }

  .k-grid-header {
    border-color: #dee2e6;
    color: #212529;
    background-color: #9f9f9f;
  }

  .k-grid col.k-sorted,
  .k-grid th.k-sorted {
  }

  .k-grid-header .k-i-sort-asc-sm,
  .k-grid-header .k-i-sort-desc-sm,
  .k-grid-header .k-sort-order {
    color: #ffffff;
    border: none;
  }
}

/**************** Sales-Module-Compare ************/
.sales-compare-heading {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.sales-compare-main-dropdown {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.sales-compare-grid {
  margin-top: 2rem;

  .k-grid {
    border-color: transparent !important;
  }

  .k-header {
    background-color: #9f9f9f;
    border: none;
    color: #ffffff;
    font-family: "Helvetica Neue normal", sans-serif;
  }

  .k-grid-table {
    width: 100% !important;
  }

  .k-grid-header-locked>table,
  .k-grid-header-wrap>table {
    width: 100% !important;
  }

  .k-grid-header {
    border-color: #dee2e6;
    color: #212529;
    background-color: #9f9f9f;
  }

  .k-grid col.k-sorted,
  .k-grid th.k-sorted {
   
  }

  .k-grid-header .k-i-sort-asc-sm,
  .k-grid-header .k-i-sort-desc-sm,
  .k-grid-header .k-sort-order {
    color: #ffffff;
    border: none;
  }
}

/**************** All Customers ************/
.customer-main-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;

  .customer-close-icon {
    li {
      font-size: 1.2rem;
      color: #008db9;
      margin-top: 0.3rem;
      margin-right: 0.3rem;
    }
  }

  .customer-sub-header {
    padding: 0.1rem 2rem;

    .customer-header {
      font-family: "HelveticaNeue Medium";
      color: #393939;
      font-size: 26px;
    }
  }

  .customer-grid-border {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 9px;
    padding: 2rem 2rem;
    margin-bottom: 3rem;
    margin-left: 2rem;
    margin-right: 2rem;

    .customer-link {
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }

    .k-grid {
      border-color: transparent !important;
    }

    .k-header {
      background-color: #9f9f9f;
      border: none;
      color: #ffffff;
      font-family: "Helvetica Neue normal", sans-serif;
    }

    .k-grid-table {
      width: 100% !important;
    }

    .k-grid-header-locked>table,
    .k-grid-header-wrap>table {
      width: 100% !important;
    }

    .k-grid-header {
      border-color: #dee2e6;
      color: #212529;
      background-color: #9f9f9f;
    }

    .k-grid col.k-sorted,
    .k-grid th.k-sorted {
  
    }

    .k-grid-header .k-i-sort-asc-sm,
    .k-grid-header .k-i-sort-desc-sm,
    .k-grid-header .k-sort-order {
      color: #ffffff;
      border: none;
    }
  }
}


.tax-report-grid {

  .k-grid td,
  .k-grid .k-grid-content,
  .k-grid .k-grid-header-locked,
  .k-grid .k-grid-content-locked {
    font-size: 12px;
  }

  .k-column-title {
    font-size: 14px;
  }
}

.taxes-border-header {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 0rem 2rem;

  .taxes-fullview {
    overflow: auto;
    width: 100%;
    height: 100% !important;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: white;
    padding: 1rem 2rem;
  }

  .taxes-header-search {
    display: flex;
    justify-content: flex-end;

    .taxes-search-input {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 5px;
      align-items: center;

      input {
        border: none;
      }
    }

    .taxes-download-header {
      .taxes-download-button {
        width: 2.8rem;
        height: 2.5rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
      }
    }

    .taxes-fullscreen-header {
      .taxes-fullscreen-button {
        width: 2.8rem;
        height: 2.5rem;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;
      }
    }
  }
}