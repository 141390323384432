
@font-face {
    font-family: "alexbrush-regular";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/alexbrush-regular.woff"); }
  
  @font-face {
    font-family: "allura-regular";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/allura-regular.woff"); }
  
  @font-face {
    font-family: "blackjack";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/blackjack.woff"); }
  
  @font-face {
    font-family: "dancingscript-regular";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/dancingscript-regular.woff"); }
  
  @font-face {
    font-family: "greatvibes-regular";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/greatvibes-regular.woff"); }
  
  @font-face {
    font-family: "learning_curve_regular_ot_tt";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/learning_curve_regular_ot_tt.woff"); }
  
  @font-face {
    font-family: "blunt-webfont";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/blunt-webfont.woff"); }
  
  @font-face {
    font-family: "carolina-webfont";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/carolina-webfont.woff"); }
  
  @font-face {
    font-family: "champignon-webfont";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/champignon-webfont.woff"); }
  
  @font-face {
    font-family: "eager-webfont";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/eager-webfont.woff"); }
  
  @font-face {
    font-family: "hoffm-webfont";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/hoffm-webfont.woff"); }
  
  @font-face {
    font-family: "jacoba_bold-webfont";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/jacoba_bold-webfont.woff"); }
  
  @font-face {
    font-family: "mrdehaviland-webfont";
    font-weight: normal;
    font-style: normal;
    src: url("fonts/mrdehaviland-webfont.woff"); }
  