@import './fonts.scss';

.buzops{
    &-disable {
        cursor: no-drop;
    }
    &-clickable {
        cursor: pointer;
        pointer-events: all;
    }
    &-two-equal-boxes{
        border: 1px solid #e9e9e9;
        float: left;
        width: 50%;
        text-align: center;
        overflow: hidden;
        word-wrap: break-word;
        cursor: pointer;
        &:hover{
            background-color: #e9e9e9;
        }
    }
    &-type-signature{
        background-color: #F9F9FA;
        border: none;
        font-size: 25px !important;
        height: 250px!important;
        text-align: center!important;
        width: 100%;
    }
    &-grid{
        .k-grid th, .k-grid td {
            border-style: 'none';
        }
    }
}

