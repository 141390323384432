.react-form-builder-form {

  .rfb-item {
    label {
      span {
        // background: white;
        font-weight: bold;
        // padding: 0 10px;
      }
      span.label-required.asterisk {
        color:red;
      }
      
    }
    label.MultiRowLabel {
      span {
        background: #ffffff;
        padding: 0px 10px;
        display: none;
      }
    }

    .row {
      padding: 0 25px;
      border: 1px solid #6da6e3;
      border-radius: 10px;
      margin-top: -18px;
    }
  }
}

.react-form-builder {
  max-height: 88vh;
  overflow: hidden;
  .react-form-builder-preview {
    // position: relative;
    // width: 70%;
    border: 1px solid #ddd;
    background: #fafafa;
    padding: 10px;
    box-shadow: 0 0 2px 1px rgba(0,0,0,0.1);
    min-height: 85vh;
    width:100%;
    .preview-content {
      max-height: 90vh;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .preview-page-break {
      padding: 5px 0;
      border-top: 2px dotted #ccc;
      border-bottom: 2px dotted #ccc;
      background: #eee;
      text-align: center;
      width: 100%;
      font-weight: bold;
      margin: 5px 0;
      background: repeating-linear-gradient(
        45deg,
        #fff,
        #fff 10px,
        #eee 10px,
        #eee 20px
      );
    }

    label {
      font-weight: normal;
    }

    .bold {
      font-weight: bold;
    }
    .italic {
      font-style: italic;
    }

    .no-image {
      background: #eee;
      width: 100px;
      height: 100px;
      border: 1px solid #ddd;
      text-align: center;
      padding-top: 35px;
    }

    .option-inline {
      display: inline-block !important;
      margin-right: 10px !important;
    }
    .form-label {
      display: block !important;
    }

    .edit-form {
      position: fixed;
      background: #fafafa;
      padding: 30px;
      border-left: 0;
      box-shadow: none;
      top: 0;
      left: -1000px;
      height: 100%;
      // width: 0;
      width: 715px;
      transition: .5s;
      overflow-y: auto;

      h4, .dismiss-edit {
        margin-bottom: 30px;
      }
      .dismiss-edit {
        cursor: pointer;
        margin-top: 10px;
        font-size: 125%;
      }
      textarea {
        width: 100%;
      }
      .form-group {
        .form-group-range {
          label {
            display: block;
          }
          input {
            display: inline-block;
            width: 21%;
            margin-left: 0;
            &:last-of-type {
              width: 78%;
              margin-left: 1%;
            }
            &:first-of-type {
              width: 21%;
              margin-left: 0;
            }
          }
        }
      }
      .dynamic-option-list {
        margin-bottom: 15px;
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            margin-top: 10px;
            input {
              &.form-control {
                width: 84.26%;
                float: left;
                &[type="checkbox"] {
                  border: none;
                  box-shadow: none;
                  margin-top: 0;
                }
              }
              margin-right: 10px;
            }
            button {
              float: left;
              margin-right: 5px;
            }
            .dynamic-options-actions-buttons {
              margin-left: 20px;
            }
          }
        }
      }
    }
    .Sortable {
      transition: opacity .25s ease-in;
      position: relative;
      cursor: move;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .rfb-item {
       
        padding: 10px 20px;
        &.SortableItem {
          position: relative;
          .form-group {
            pointer-events: none;
            label, select, input, a {
              cursor: move;
            }
            input[type='date'] {
              height: 42px;
            }
            .react-star-rating {
              display: block;
            }
            .checkbox-label, .radio-label {
              font-weight: normal;
              display: block;
            }
            .label-required {
              margin-left: 5px;
            }
          }
          &.is-dragging {
            position: absolute;
            z-index: 1688;
            border: 2px #ccc dashed;
            background: #fff;
            cursor: move;
            padding: 10px 20px;
          }
        }
        &.is-placeholder {
          display: block;
          z-index: auto;
          opacity: .4;
          border: 2px #ccc dashed;
        }
        .toolbar-header {
          opacity: 0; // visible
          z-index: 100;
          top:13px;
          position: relative;
          .badge {
            margin:0;
          }
          .label {
            position: absolute;
            left: 20px;
          }
          .toolbar-header-buttons {
            position: absolute;
            right: 0px;
            margin-top:-20px;
          }
        }
        &:hover {
          box-shadow: inset 0 0 5px #ddd;
          background: #fff;
          select, input {
            cursor: move;
            pointer-events: none;
          }
          .toolbar-header {
            opacity: 1;
          }
        }
      }
    }
    .is-editing {
      // .Sortable {
      //   opacity: .5;
      //   transition: opacity .25s ease-in;
      // }
      .edit-form {
        z-index: 2000;
        border-right: 1px solid #ddd;
        box-shadow: 0 0 2px 1px rgba(0,0,0,0.1);
        // width: 80%;
        left: 0;
        transition: .5s;
      }
    }
  }

  .react-form-builder-toolbar {
    width: 100%;
    background: #fff;
    // margin-top: -30px;

    h4 {
      margin-top: 0;
      text-align: center;
    }
    .form-group {
      padding: 10px;
      border: 0px solid #008db9;
    }
    ul {
      padding: 0;
       li {
        cursor: pointer;
        list-style: none;
        margin: 5px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        i {
          margin: 0 15px 0 10px;
        }
      }
    }
    .FieldTemplateList {
      max-height: 80vh;
      overflow: auto;
      .FieldTemplateItems {
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-size: 10px;
        li.FieldTemplateItem {
          background-color: #fff;
          color: #008DB9;
          font-weight: 600;
        }
      }
    }
  }
}
.react-form-builder .react-form-builder-toolbar ul.k-tabstrip-items li{
border-radius: 0;
width: 46%;
}
.image-upload-container {
  position: relative;
  .image-upload {
    position: relative;
    opacity: 0;
    z-index: 2;
    height: 55px;
  }

  .image-upload-control {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
}

.image-upload-preview {
  border: 4px solid #fff;
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);
}

.file-upload-preview {
  border: 4px solid #fff;
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);
  padding: 5px;
}

.btn-image-clear {
  margin: 15px 0;
  padding: 15px;
}

.btn-file-upload-clear {
  margin: auto;
  padding: 10px;
}

@media ( max-width: $screen-sm-min ) {
  .react-form-builder {
    position: relative;

    .react-form-builder-preview {
      width: 100%;

      .edit-form {
        width: 100%;
        overflow: scroll;

        .btn {
          margin-top: 10px;
        }

        .dynamic-option-list ul li input.form-control {
          width: 100%;
        }
      }

      .Sortable .rfb-item .toolbar-header .toolbar-header-buttons {
        right: -15px;
        .btn {
          margin-right: 5px;
          border-radius: 50%;
          padding: 7px 0px 0 3px;
          width: 35px;
          height: 35px;
        }
      }
    }

    .react-form-builder-toolbar {

      h4 {
        padding: 10px;
        background: #7a0019;
        color: #fff;
      }

      width: 100%;
      // position: fixed;
      z-index: 900;
      bottom: -320px;

      -webkit-transition: transform 0.5s;
      transition: transform 0.5s;

      -webkit-transform: translate(0,0);
      transform: translate(0,0);

      right: 0;
      border-top: 2px solid #ccc;

      ul {
        height: 300px;
        overflow-x: scroll;
      }
    }

    .active-toolbar {
      -webkit-transform: translate(0,-300px);
      transform: translate(0,-300px);
    }
  }

  .m-signature-pad {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    min-width: 250px;
    min-height: 140px;
    margin: 0;
  }
}

.visible_marks {
  display: block;
  width: 100%;
  padding: 0 4px;
  label {
    text-align: center;
    display: inline-block;
    padding: 0;
    margin: 0;
    font-weight: normal;
    &:first-of-type {
      text-align: left;
    }
    &:last-of-type {
      text-align: right;
    }
  }
}

.m-signature-pad {
  font-size: 10px;
  width: auto;

  .m-signature-pad--body {
    width: auto;
    height: 200px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    height: 200px;
    border-radius: 4px;

    canvas {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
    }
  }

  .m-signature-pad--footer {
    position: relative;
    margin-top: 10px;
  }
}

.form-place-holder {
  margin:0px; 
  border:2px dashed #bbb;
  -moz-border-radius:5px;
  -webkit-border-radius:5px;
  border-radius:5px;
  padding:25px;
  text-align:center;
  font:20pt bold,"Vollkorn";color:#bbb
}

.form-place-holder-hidden {
  min-height: 50px;
}

.required-asterisk {
  color: red;
}


.viewFormBuilder   {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 5px;
  align-items: center;
  background: #f8f9fa !important;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
}

.sidebarrightheading{
  font-weight: 600;
}
.fieldslabels{
  font-size: 13px !important;
}
.fieldstabs li{
  width: 50%;
}
.formlistheading{
  font-size: 12px   !important;
  font-weight: 700   !important;
}

.actiobtnform{
  background-color: transparent;
  border-color: transparent;
  outline-color: transparent;
  outline-width: 0px !important;
  display: flex;
}
.actiobtnform img{
  width: 18px;
  margin-right: 5px;
}
.bz-classList-container.k-card > .k-card-body .k-listview .bz-forms-list-new.formlist{
  grid-template-columns: 2fr 1fr 1fr 1.5fr 1.5fr 1fr 1fr auto;
  border-color: #eaeaea !important;
  box-shadow: none !important;
}
.bz-main-provider > .bz-provider-name > h6.responses{
  text-align: center;
  color:#008DB9 !important;
  font-weight: 600;
}
.ft600{
  font-weight: 600;
}
.bz-classList-container.k-card > .k-card-body .k-listview .bz-saved-forms-list-new.responselist{
  grid-template-columns: 2fr 2fr 1fr 1fr  2fr auto;
  border-color: #eaeaea !important;
  box-shadow: none !important;
}
.bz-classList-container.k-card > .k-card-body .k-listview .bz-saved-forms-list-new.responselist.bz-saved-forms-referral{

  grid-template-columns: 2fr 1.5fr 1fr 1fr 1fr  2fr auto;
  align-items: start;
}
.react-form-builder-toolbar .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-state-active .k-link{
  color:#008db9
  }
  .disable-shortcode .actiobtnform, .disable-shortcode small{
cursor: none;
opacity: .4;
  }
 
  .react-form-builder .k-tabstrip-items {
    display: flex !important;
  }
  .borderfiledadd{
    border: 1px solid #000;
    padding: 2px;
    font-size: 18px;
    border-radius: 2px;
  }

  .react-form-builder-toolbar .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-state-active .borderfiledadd{
    border: 1px solid #008db9;
    }

    .editformbuzops{
      color:#737070
    }

    .deleteformbuzops{
      color:#ed1716
    }

    .bzo-class-window .k-button.preview{
      background:transparent;
      border:0px solid #d9d9d9;
      color:#555555;
      border-right: 1px solid #d1cdce;
      margin: 0;
    }

    .bzo-class-window .preveiwform{
      background: #f8f8f8;
      border:1px solid #d9d9d9;
      color:#555555;
      border-radius: 5px;
      display: flex;
      min-height: 35px;
    }
    .bzo-class-window .draftbtn .k-button{
      margin: 0;
      border: 0px solid transparent;
      background-color: #eeeeee !important;
      color:#555555 !important;
    }
    .react-form-builder-toolbar .form-group label{
      font-size: 13px;
    }

    .bz-form-response-table{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
    border: 1px solid #eee;

    div{
      padding: 7px;
    }
    }
    
    .bz-form-response-table .form-description, .bz-form-response-table .form-values{
      width: 50%;
    }
    .bz-form-response-table .form-values{
      border-left: 2px solid #eee;  }
    .signform{
      width: 200px;
    }

    .btnformsuccess{
      padding: 0px 5px !important;
      box-shadow: none  !important;
      background-color: #0268a9 !important;
      border-radius: 50px;
      margin-right: 5px;
    }

    .btnformdanger{
      padding: 0px 5px !important;
      box-shadow: none  !important;
      background-color: #d73539!important;
      border-radius: 50px;
      margin-right: 5px;
    }

    .dynamic-options-actions-buttons{
      padding: 5px 0;
    }