.bz-ribbon-1{
  background-color: #008db9;
    min-width: 35px;
    position: relative;
    cursor: default;
    user-select: none;
    margin-left: 1rem;
    color: #fff;
    display: inline-block;
    text-align: center;
    padding: 0.15rem;
    height: 1.4rem;
}
.bz-ribbon-1:before,
.bz-ribbon-1:after {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  border-style: solid;
  top: 0;
}
.bz-ribbon-1:before {
  border-width: 1.4rem 0.6rem 0 0;
}
.bz-ribbon-1:after {
  border-width: 1.4rem 0.6rem 0 0;
}
.bz-ribbon-1.stage:before {
  border-width: 1.4rem 0.6rem 0 0;
}
.bz-ribbon-1.stage:after {
  border-width: 1.4rem 0.6rem 0 0;
}
.bz-ribbon-1:before{
  left: -0.6rem;
  border-color: transparent #008db9;
}
.bz-ribbon-1:after{
  right: -0.6rem;
  border-color: #008db9 transparent;
}
.bz-ribbon-1.stage{
  background-color: #ff7a0e;
}
.bz-ribbon-1.stage:before{
  border-color: transparent #ff7a0e;
}
.bz-ribbon-1.stage:after{
  border-color: #ff7a0e transparent;
}
.bz-ribbon-arrow{
  background-color: #ff7a0e;
    min-width: 42px;
    position: relative;
    cursor: default;
    margin-left: 1rem;
    color: #fff;
    display: inline-block;
    text-align: center;
    padding: 0.15rem;
   min-height: 21px;
}
.bz-ribbon-arrow:before,
.bz-ribbon-arrow:after {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 10.5px 0 10.5px 10.5px;
  top: 0;
}
.bz-ribbon-arrow:before{
  border-color: #ff7a0e transparent;
  left: -10px;
}
.bz-ribbon-arrow:after{
  border-color: transparent #ff7a0e;
  right: -10px;
}

.k-animation-container{
  > .k-group{
 
      .k-item.k-state-selected{
        color: #fff!important;
        background-color: #008db9;
        &:hover{
          background-color: #008db9!important;
        }
      }
    
      .k-item:hover{
        color: #000;
      }
    }
  }


.bz-pipedrive-container{
  .bz-pipedrive-url-row{
    display: grid;
    grid-template-columns: 1fr 6rem;
    grid-column-gap: 1rem;
    .btn-container{
      .k-button{
        margin: 0;
        &:first-of-type{
          margin-right: 1rem;
        }
      }
    }
  }
  .bz-form-group-row{
    display: grid;
    grid-template-columns: calc(100% - 16rem) 13rem;
    grid-column-gap: 3rem;
    > .form-label{
      margin-bottom: 0!important;
      font-size: 0.875rem!important;
    }
    > .form-label.k-label-empty{
      display: inherit;
    }
   > .form-dropdown{
     display: flex;
     justify-content: flex-end;

     .bz-dropdown-btn{
       
       .k-button.k-flat{
        min-width: 204px;
        padding: 0;
        padding-right: 1.25rem!important;
        text-transform: capitalize;
        color: #008db9;
        justify-content: flex-end;
       }
       .fa-chevron-down{
         right: 0;
       }
     }
   }
   h6{
     font-weight: 700;
   }
 
  }
  .pipedrive-stage{
    margin-bottom: 0rem;
  }
  .k-card-body{
    > div.prospect-blk{
        .stage-list{
           > .pipedrive-stage{
            .form-dropdown{
              .k-button.k-flat{
                min-width: 120px;
              }
            }
          }
        }
    }
    > div.subscription-blk{
      .stage-list{
         > .pipedrive-stage{
          .form-dropdown{
            .k-button.k-flat{
              min-width: 180px;
            }
          }
        }
      }
  }
  }
}

.bz-pipedrive-container .stage-list{
  &::before{
    content:"-";
    font-size: 1.1rem;
    left: 0;
    top: -4px;
  }
   display: grid;
   grid-template-columns: 1rem calc(100% - 1rem);
   margin-bottom: 1.5rem;
   margin-left: 1.5rem;
   align-items: baseline;
}
  

.bz-footer-fixed-btn{
  > .btn-container{
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
    right: 0;
    padding: 0.75rem 1rem;
  }
}
@media screen and (min-width:1025px){
  .bz-pipedrive-container{
    > .k-card-body{
      max-width: 968px;
    }
  }
  .bz-form-group-row{
    grid-template-columns: calc(100% - 16rem) 13rem !important;
  }
  .bz-footer-fixed-btn > .btn-container > .k-button{
    margin-left: 840px;
  }
}
@media only screen and (max-width: 1024px){
  .bz-pipedrive-container .bz-pipedrive-url-row{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width : 886px){
  .bz-pipedrive-container .bz-form-group-row > .form-label::before{
    margin-left: 0;
  }
}
@media only screen and (max-width: 768px){
  .bz-pipedrive-container .bz-pipedrive-url-row{
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1rem;
    .btn-container > .k-label{
      display: none;
    }

  }
}
@media screen and (max-width:680px){
  .bz-pipedrive-container .bz-form-group-row{
    grid-template-columns: 1fr;
  }
  .bz-pipedrive-container .bz-form-group-row > .form-dropdown{
    justify-content: flex-start;
  }
 
  .bz-pipedrive-container .bz-form-group-row > .form-label{
    margin-bottom: 0.5rem!important;
    line-height: 1.2rem;
    .bz-ribbon-1{
      line-height: 1rem;
    }
  }
 
  .bz-pipedrive-container .bz-form-group-row > .form-dropdown .k-dropdown{
    max-width: 100%!important;
    width: 100%!important;
  }
  .bz-pipedrive-container .bz-form-group-row{
    h6{
      margin-top: 1rem!important;
      margin-bottom: 0!important;
    }
    .h6{
      margin-top: 0!important;
      margin-bottom: 0.5rem!important;
    }
  }
  .bz-pipedrive-container .stage-list{
    margin-left: 0;
  }
  .bz-pipedrive-container .bz-form-group-row > .form-dropdown .bz-dropdown-btn .k-button.k-flat{
    justify-content: flex-start;
    padding-left: 0!important;
  }
  .bz-pipedrive-container .bz-form-group-row > .form-dropdown .bz-dropdown-btn .fa-chevron-down{
    position: unset;
  }
}

@media only screen and (max-width: 620px){
  .bz-pipedrive-container .bz-pipedrive-url-row{
    grid-template-columns: 1fr;
    
  }
}
@media only screen and (max-width: 479px){
  .bz-pipedrive-container .bz-form-group-row .k-dropdown{
    width: 100%!important;
  }
  .bz-pipedrive-container .pipedrive-stage{
    margin-bottom: 1rem;
  }
}