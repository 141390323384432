/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 90%;
  color: #32325d;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin: 0;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.StripeElement+.bz-square-btn-wrapper {
  margin-left: 0!important;
  margin-right: 0!important;
  justify-content: flex-start;
  margin-top: 1rem!important;

}

.bz-addCard-dialog,
.bz-notes-dialog {
  .StripeElement {
    margin: 1.5rem 0;
    width: 380px;

    .CardField-number {
      width: 12rem;
    }
  }

  .StripeElement + .bz-square-btn-wrapper {
    margin: 0rem !important;
    justify-content: flex-start;
  }
}
.payment-panel-form{
  .StripeElement,
  .StripeElement+.bz-square-btn-wrapper{
    max-width: 400px;
    margin-left: 1.5rem!important;
    margin-right: 1.5rem!important;
  }

}
@media (max-width: 450px) {
  .bz-addCard-dialog,
  .bz-content-body {
    .StripeElement {
      width: 100%;
    }
  }
 
}
