.k-item {
    display: block !important;
}
.section-notification {
    margin-left: 10px;
    align-self: flex-start;
}
.bzc-edit-session-options-row {
    .section-notification {
        margin-left: auto;
        margin-right: 15px;
        display: flex;
        align-self: center;
    }
}