.self-signin {
  display: grid;
  place-items: center;

  .bzo-checkIn-login-card {
    width: 400px;
  }
}

.checkIns-home {
  display: grid;
  height: 65vh;
  place-items: center;
  justify-content: center;
  align-content: center;
  grid-auto-flow: column;

  .checkin-card-container {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2rem;
    justify-content: center;
    align-content: center;
  }

  .checkInCards {
    width: 180px;
    height: 180px;
    border-radius: 0.72rem;
    border: 1px solid #008db9;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .checkIncard-text {
      font-weight: 600;
      color: #008db9;
    }
  }

  .disabled {
    opacity: 0.4;
    box-shadow: none;
    cursor: not-allowed;
  }
}

.Self-CheckIn {
  display: block;

  align-items: center;

  .scan-card-container {
    width: 30%;
  }

  .k-textbox:disabled,
  .k-textbox[disabled],
  .k-textbox.k-state-disabled {
    background-color: lightgray !important;
    color: #000;
  }
}

.user-content {
  padding-top: 0rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .user-details {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    border-radius: 0.3rem;
    background-color: #fff;
  }
}

.today-appointments {
  padding-top: 1rem;
  width: 100%;
  position: relative;

}

.appts-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
}

.ba-subscription-details {
  .card-subtitle p {
    padding: 1rem 0;
  }

  margin: 1rem;

  >h5 {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    text-align: left;
  }

  .ba-subscriptions-item {
    background-color: #f2f1f1;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .ba-showMore-link {
    font-size: 75%;
    color: #008db9;
    font-weight: 500;
    display: inline-flex;
    text-align: center;
    align-items: center;
    cursor: pointer;
  }

  .ba-badge-Active {
    color: #28c76f;
    background-color: #d5efe0;
  }

  .ba-badge {
    display: inline-block;
    font-size: 70%;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    vertical-align: initial;
    padding: 0.2rem 0.5rem;
    text-align: center;
    border-radius: 0.358rem;
  }
}

.site-checkin-container {

  .search-client {
    display: flex;
    justify-content: space-between;
  }
}

.Self-CheckIn-backbtn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.site-checkin-livestream {
  border-radius: 0;
  border: 0;
  .filter-container {
    display: flex;
    justify-content: space-between;
  }

  .filter-options {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
  }

  .ba-activity-card {
    margin-bottom: 25px;

    >.card-body {
      padding: 0.5rem;
    }

    .ba-activity-row {

      display: flex;
      align-items: center;
      justify-content: space-between;

      >.ba-activity-coloumn-1 {
        flex: 0 0 8rem;
        padding: 0 1rem;

        >.ba-date-container {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          max-width: 7rem;

          >.ba-time {
            font-size: 12px;
            font-weight: 600;
            text-align: center;
            display: block;
          }

          >.ba-date {
            display: block;
            font-weight: 600;
            color: #000;
            font-size: 14px;
          }

          >.ba-month {
            display: block;
          }
        }
      }

      >.ba-activity-inner-row {
        display: flex;
        flex: 0 0 60%;

        >.ba-activity-coloumn-2 {
          flex: 0 0 40%;
          padding: 0 1rem;
          align-self: center;

          .card-title {
            text-align: left;
          }
        }

        >.ba-activity-coloumn-3 {
          flex: 0 0 30rem;
          padding: 0 1rem;

          >.ba-subscription-details {
            >h5 {
              font-size: 0.875rem;
              margin-bottom: 0.25rem;
              text-align: left;
            }

            .ba-subscriptions-item {
              background-color: #f2f1f1;
              padding: 0.5rem;
              border-radius: 0.5rem;

            }
          }
        }
      }

      >.ba-activity-coloumn-4 {
        flex: 0 0 6rem;

        >img {
          width: 4.5rem;
          border-radius: 50%;
          height: 4.5rem;
        }
      }

      .coloumn {
        padding: 0 1rem;
        flex: 0 0 25%;
      }

    }

    .ba-showMore-link {
      font-size: 75%;
      color: #008db9;
      font-weight: 500;
      display: inline-flex;
      text-align: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: #ff7a0e
      }

      >.svg-inline--fa {
        margin-left: 0.25rem;
        margin-top: 0.15rem;
      }
    }
  }

  .livestream_head {
    .ba-activity-row {
      height: 100%;
    }
  }
}

.Scan-action-btns {
  padding-top: 1.2rem;
  padding-left: 0.5rem;
}

.clubname,
.checkedinlable {
  color: #a1a5b7;
  font-size: 12px;
  text-transform: capitalize;
}

.customername {
  margin-bottom: 5px;
  text-transform: capitalize;

}

.livestream_time,
.ba-date {
  width: 100%;
  display: block;
}


.customername {
  font-size: 14px;
}

.ba-date-month {
  font-size: 14px;
  font-weight: normal;
  display: block;
}

.ba-date {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  display: block;
}

.livestream_time,
.clubname,
.checkedinlable,
.bz-dateTime {
  font-size: 12px;
}

.livestream-cont .k-listview-content {
  overflow-x: hidden;
  padding: 5px;
}

.checkedinfor {
  color: #008db9
}

.site-checkin-livestream .bzc-checkins-list>.k-listview-content>.ba-activity-card .livestream_client>.media .bz-user-img {
  border-radius: 5px
}

.border-livestream {
  border: 1px solid #e3e4ed;
  border-width: 0 1px;
}

.livestream-filter-cont {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
}

.livestreamname {
  h6 {

    font-size: 0.9rem;
    margin-bottom: 0.1rem;
    color: #008db9;
  }

  small {
    display: block;
  }

}

.icon-color {
  color: #008db9;
}

.locationicon,
.mdcalendaricon {
  width: 50%;
  display: inline-block;
}

.live-stream-left-search {
  width: 80%;
}

.live-stream-right-search {
  width: 20%;
}

@media (max-width:767px) {
  .border-livestream {
    border-width: 1px 0;
  }
}

.k-listview-content div.bz-card:first-child {
  background-color: #E2FFFA;
}

.bz-mybookings-dialog-box .k-dialog .k-dialog-content p {
  display: block;
}

.checkedin-success.selfcheckins-success {
  font-size: 12px;
  top: -0.5rem
}