@import "../../../scss/variables/variables.scss";

.k-reset {
}

.k-reset .k-button {
  background-color: $primaryColor;
  color: $whiteColor;
}

.qr-clear {
  margin-left: 70px;
  color: crimson;
}

